<template>
  <div :class="$style.options">
    <h5 :class="$style.title">{{ title }}</h5>
    <Checkbox
      v-for="(option, index) in shownOptions"
      :key="`shown-${index}`"
      :class="$style.checkbox"
      :checked="value.includes(option)"
      @change="toggleOptions($event, option)"
    >
      {{ option }}
    </Checkbox>
    <template v-if="show !== null">
      <a
        v-if="!isExpanded"
        :class="$style.expand"
        href=""
        role="button"
        @click.prevent="isExpanded = true"
      >
        показать все
      </a>
      <template v-else>
        <Checkbox
          v-for="(option, index) in hiddenOptions"
          :key="`hidden-${index}`"
          :class="$style.checkbox"
          :checked="value.includes(option)"
          @change="toggleOptions($event, option)"
        >
          {{ option }}
        </Checkbox>
      </template>
    </template>
  </div>
</template>

<script>
import Checkbox from '@/components/atoms/Checkbox.vue'
export default {
  components: {
    Checkbox,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    show: {
      type: Number,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isExpanded: false,
    }
  },
  computed: {
    shownOptions() {
      return this.show === null
        ? this.options
        : this.options.slice(0, this.show)
    },
    hiddenOptions() {
      return this.show === null ? [] : this.options.slice(this.show)
    },
  },
  methods: {
    toggleOptions(checked, value) {
      const options = [...this.value]
      if (checked) {
        options.push(value)
      } else {
        options.splice(
          options.findIndex((option) => option === value),
          1,
        )
      }
      this.$emit('input', options)
    },
  },
}
</script>

<style lang="scss" module>
.options {
  display: flex;
  flex-direction: column;
  .title {
    color: $dark-gray;
    line-height: 1.25rem;
  }
  .expand {
    color: $dark-orange;
    margin-top: 1.063rem;
  }
  .checkbox {
    margin-top: 1.063rem;
  }
}
</style>
