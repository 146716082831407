<template>
  <form
    :class="$style.filters"
    @reset.prevent="$emit('reset')"
    @submit.prevent="$emit('apply')"
  >
    <h2 v-if="title" :class="$style.title">
      {{ title }}
    </h2>
    <div :class="$style.inputGroup">
      <Input
        :value="value.price.min"
        placeholder="Цена от"
        @input="
          $emit('input', { ...value, price: { ...value.price, min: $event } })
        "
      />
      <Input
        :value="value.price.max"
        placeholder="Цена до"
        @input="
          $emit('input', { ...value, price: { ...value.price, max: $event } })
        "
      />
    </div>
    <Checkbox
      :class="$style.checkbox"
      :checked="value.withDiscountPrice"
      @change="$emit('input', { ...value, withDiscountPrice: $event })"
    >
      только товары по спец цене
    </Checkbox>
    <br />
    <Checkbox
      :class="$style.checkbox"
      :checked="value.isAvailable"
      @change="$emit('input', { ...value, isAvailable: $event })"
    >
      только товары в наличии
    </Checkbox>
    <br />
    <Checkbox
      :class="$style.checkbox"
      :checked="value.isPortfolioAddwine"
      @change="$emit('input', { ...value, isPortfolioAddwine: $event })"
    >
      только портфель AddSeller
    </Checkbox>
    <div :class="$style.devider"></div>
    <FilterOptions
      :value="value.groups"
      title="Аксессуары"
      :show="3"
      :options="sortedGroups"
      @input="$emit('input', { ...value, groups: $event })"
    />
    <div :class="$style.devider"></div>
    <FilterOptions
      :value="value.brands"
      title="Бренды"
      :show="3"
      :options="sortedBrands"
      @input="$emit('input', { ...value, brands: $event })"
    />
    <div :class="$style.devider"></div>
    <div :class="$style.actions">
      <Button type="submit" small> Применить </Button>
      <Button type="reset" color="outline-secondary" small> Сбросить </Button>
    </div>
  </form>
</template>

<script>
import Input from '@/components/atoms/Input.vue'
import Button from '@/components/atoms/Button.vue'
import Checkbox from '@/components/atoms/Checkbox.vue'
import FilterOptions from '@/components/moleculs/FilterOptions.vue'
export default {
  components: {
    Input,
    Button,
    Checkbox,
    FilterOptions,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
      default: () => ({
        price: {
          min: null,
          max: null,
        },
        withDiscountPrice: false,
        isAvailable: false,
        groups: [],
        brands: [],
      }),
    },
    options: {
      type: Object,
      default: () => ({
        groups: ['Декантеры', 'Бокалы'],
        brands: [
          'Coravin',
          'Sophienwald',
          'Peugeot',
          'Italesse Vinocchio',
          'Italesse Etoile',
        ],
        withDiscountPriceCount: 0,
      }),
    },
  },
  computed: {
    sortedGroups() {
      const array = this.options.groups
      return array.sort((a, b) => (a !== b ? (a < b ? -1 : 1) : 0))
    },
    sortedBrands() {
      const array = this.options.brands
      return array.sort((a, b) => (a !== b ? (a < b ? -1 : 1) : 0))
    },
  },
}
</script>

<style lang="scss" module>
.filters {
  padding: 1rem;
  .title {
    margin-bottom: 1rem;
  }
  .inputGroup {
    display: flex;
    > div {
      flex: 1 1 auto;
      &:first-child {
        margin-right: 0.5rem;
      }
    }
  }
  .checkbox {
    margin-top: 1rem;
  }
  .actions {
    display: flex;
    [type='submit'] {
      flex: 1 1 auto;
      margin-right: 1.5rem;
    }
  }
  .devider {
    margin: 1.5rem 0;
    border-top: 0.063rem solid $extra-light-gray;
  }
}
</style>
